import config from 'modules/config/merge.config.json';

/**
 * A function that takes a dot separated key string, returning a configuration value
 * in either the base config or the site specific one.
 *
 * Ex: key: 'access.this.config.value'
 */
export default function getConfig(key: string = '') {
  if (key === '') return config;

  try {
    // We create a REAL copy of the config found as to not cause stupid reference errors
    return JSON.parse(JSON.stringify(key.split('.').reduce((o: any, i) => o[i], config)));
  } catch (e) {
    return '';
  }
}
