import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { useAppState } from 'lib/hooks/useAppState';
import { ReactNode } from 'react';
import { useToggle } from 'react-use';
import { Accordion } from './Accordion';

export interface StandaloneAccordionProps extends StandaloneComponentProps<typeof Accordion> {
  title?: ReactNode;
  content?: ReactNode;
  toggle?: React.ReactNode;
}

export const StandaloneAccordion: StandaloneComponent<StandaloneAccordionProps> = ({
  title,
  content,
  toggle,
  ...props
}) => {
  const [{ device }] = useAppState();
  const [isOpen, toggleOpen] = useToggle(device === 'desktop');

  return (
    <Accordion {...props} data-open={isOpen ? '' : undefined}>
      <Accordion.Group>
        <Accordion.Headline>{title}</Accordion.Headline>
        {(device === 'mobile' && toggle) ?? (
          <Accordion.Toggle onClick={toggleOpen}>
            <Accordion.Icon name="showMore" />
          </Accordion.Toggle>
        )}
      </Accordion.Group>
      <Accordion.Content>{content}</Accordion.Content>
    </Accordion>
  );
};
