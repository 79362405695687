import { tw } from '@/utils/tw';
import BaseTextFieldTheme from 'base/components/TextField/theme';

const Icon = tw.theme({
  extend: BaseTextFieldTheme.Icon,
  base: `text-black group-data-[status=error]:text-red-700`,
});

const TextField = tw.theme({
  extend: BaseTextFieldTheme,
  slots: {
    label: `group-focus-within:!text-secondary-700 text-gray-800 group-hover:text-black group-data-[status=error]:text-red-700`,
    input: `outline-secondary-200 focus:ring-secondary-700 text-input-md p-2.5 group-data-[status=error]:bg-white group-data-[status=error]:outline-red-200 group-data-[status=error]:ring-red-700 focus:bg-white`,
    caption: `text-input-sm text-gray-700 group-data-[status=error]:text-red-700`,
  },
  variants: {
    variant: {
      primary: {
        label: `text-input-sm`,
        input: `h-10 peer-[.absolute]/icon:pr-7`,
      },
      secondary: {
        label: `text-input-md group-focus-within:text-input-xs group-data-[filled]:text-input-xs left-2.5`,
      },
    },
  },
});

export default Object.assign(TextField, { Icon });
