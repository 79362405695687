import { useStable } from '@/hooks/useStable';
import { StandaloneStateProviders } from 'base/components/StateProviders';

export const HantStandaloneStateProviders: typeof StandaloneStateProviders = ({ children, ...pageProps }) => {
  const { pageData } = pageProps ?? {};
  const { publishedUrl, badge } = pageData?.data ?? {};
  const affiliate = publishedUrl?.startsWith('/nyheter') ? 'nyheter' : 'noje';

  const appState = useStable(pageProps?.appState, { affiliate, badge });

  return (
    <StandaloneStateProviders {...{ appState }} {...pageProps}>
      {children}
    </StandaloneStateProviders>
  );
};
