import { useAppState } from '@/hooks/useAppState';
import { getDomain } from '@/utils/getDomain';
import { getUserLogInUrl, getUserLogOutUrl, getUserPageUrl, getUserSignUpUrl } from '@/utils/getUserAuthUrl';
import { isClient } from '@/utils/isClient';
import { mergeStable } from '@/utils/merge';
import { tryGetWindowProp } from '@/utils/tryGetWindowProp';
import jwt from 'jsonwebtoken';
import { AlltIdJwtToken } from 'lib/alltIdMyData';
import useCookies from 'lib/hooks/useCookies';
import { CreateAccountPasswordless, createAccountPasswordless } from 'lib/services/alltid';
import { usePathname } from 'next/navigation';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useEffectOnce } from 'react-use';

type Urls = {
  logInUrl: string;
  logOutUrl: string;
  signUpUrl: string;
  myPageUrl: string;
};

const getUrls = (state: Urls, returnTo: string) => {
  const params = returnTo ? { return: returnTo } : undefined;
  const alltIdLogOutUrl = isClient() && window.alltId?.getBrandLogoutURL(returnTo);

  const urls = {
    logInUrl: getUserLogInUrl(params),
    logOutUrl: alltIdLogOutUrl || getUserLogOutUrl(params),
    signUpUrl: getUserSignUpUrl(params),
    myPageUrl: getUserPageUrl(params),
  };

  return mergeStable(state, urls);
};

const isValidCookie = (cookie: string | undefined) => Boolean(cookie && cookie.length > 0);

export type UserAuthProps = ReturnType<typeof useUserAuth>;
type AlltId = Window['alltId'];

const useUserAuth = () => {
  const pathname = usePathname();
  const [{ login_token, alltIdData }] = useCookies();
  const [isUserLoggedIn, setUserLoggedIn] = useState(false);
  const [urls, setReturnTo] = useReducer(getUrls, getUrls({} as Urls, ''));
  const [alltId, setAlltId] = useState<AlltId | null>(null);
  const [userTags, setUserTags] = useState<string[]>([]);
  const [{ alltIdUserCode }, appStateDispatcher] = useAppState();

  const dispatchAlltIdUserControlCode = useCallback(
    (userCC: string | undefined) => {
      appStateDispatcher({
        alltIdUserCode: userCC,
      });
    },
    [appStateDispatcher],
  );

  useEffect(() => {
    const siteUrl = getDomain(false);
    const isMyPage = pathname.startsWith('/mina-sidor');
    const returnTo = isMyPage ? siteUrl : `${siteUrl}${pathname}`;
    setReturnTo(returnTo);
  }, [pathname, alltId?.config?.jwt]);

  useEffect(() => {
    if (login_token) {
      const decodedToken = JSON.parse(atob(login_token?.split('.')[1] ?? ''));
      dispatchAlltIdUserControlCode(decodedToken.data.cc);
    }
  }, [dispatchAlltIdUserControlCode, login_token]);

  useEffectOnce(() => {
    tryGetWindowProp<AlltId, null>('alltId', { fallbackValue: null }).then(setAlltId);
  });

  useEffect(() => {
    setUserLoggedIn(isValidCookie(login_token) && isValidCookie(alltIdData));
  }, [login_token, alltIdData]);

  useEffect(() => {
    const payload = (login_token ? jwt.decode(login_token) : {}) as AlltIdJwtToken;
    setUserTags(payload?.data?.user?.tags || []);
  }, [login_token]);

  const logOut = useCallback(() => {
    window.location.href = urls.logOutUrl;
  }, [urls.logOutUrl]);

  const createPasswordlessAccount = useCallback<CreateAccountPasswordless>(
    async (userData, newsletterSubscription) => {
      const userControlCode = await createAccountPasswordless(userData, newsletterSubscription);
      dispatchAlltIdUserControlCode(userControlCode);

      return userControlCode;
    },
    [dispatchAlltIdUserControlCode],
  );

  return useMemo(
    () => ({
      isUserLoggedIn,
      logOut,
      alltIdUserCode,
      createPasswordlessAccount,
      userTags,
      ...urls,
    }),
    [isUserLoggedIn, logOut, urls, alltIdUserCode, createPasswordlessAccount, userTags],
  );
};

export default useUserAuth;
