import { Component } from '@/types/component';
import { StandaloneEdge, StandaloneEdgeProps } from 'base/components/Edge';
import localFonts from 'next/font/local';

// Licensed fonts, please do not change
const fontPrimary = localFonts({
  display: 'swap',
  preload: true,
  src: [
    {
      path: '../../../public/fonts/ITCAvantGardeGothicBook_normal_normal_subset1.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../../public/fonts/ITCAvantGardeGothicBold_normal_normal_subset1.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../../public/fonts/ITCAvantGardeGothicBoldOblique_italic_normal_subset1.woff2',
      weight: '700',
      style: 'oblique',
    },
  ],
  variable: '--font-primary',
});

export const HantStandaloneEdge: Component<StandaloneEdgeProps> = ({ children }) => {
  return <StandaloneEdge className={fontPrimary.variable}>{children}</StandaloneEdge>;
};
